<template>
  <div class="row">
    <div class="col-12 g c col-lg-7">
      <div class="card">
        <div class="card-header text-center">
          <h4>
            <i class="fa fa-plus"></i>
            إضافة خط سير/مجموعة جديدة
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h5 for="">عنوان خط السير</h5>
            <input type="text" class="form-control" v-model="title" />
          </div>
          <div class="form-group">
            <h5 for="">التفاصيل</h5>
            <textarea
              class="form-control"
              name=""
              id=""
              v-model="details"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <h5 for="">القسم</h5>
            <select class="form-control" name="" v-model="section_id" id="">
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="card card-body">
            <h4>نقاط التوقف</h4>

            <app-collapse>
              <app-collapse-item
                v-for="point in points"
                :title="point.lat + ',' + point.long"
                :key="point.lat"
              >
                <button
                  class="btn btn-sm btn-link text-danger"
                  @click="deletePoint(point.lat)"
                >
                  <i class="fa fa-trash"></i>
                  حذف
                </button>
                <br />
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      style="width: 100%; height: 400px"
                      id="gmap_canvas"
                      :src="`https://maps.google.com/maps?q=${
                        point.lat + ',' + point.long
                      }&t=&z=13&ie=UTF8&iwloc=&output=embed`"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
          </div>
          <div class="form-group">
            <h5 for="">تحديث نقاط التوقف</h5>
            <select
              class="form-control"
              name=""
              v-model="noupdatelocation"
              id=""
            >
              <option :value="true">سماح</option>
              <option :value="false">منع</option>
            </select>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-success" @click="add()">
              إضافة خط السير <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 g">
      <div class="card card-body">
        <div class="col-12" v-if="viewmap">
          <l-map :zoom="zoom" @click="addMarker" :center="[mylat, mylong]">
            <l-tile-layer :url="url" />
          </l-map>
        </div>
        <button class="btn btn-sm btn-link" @click="viewmap = true">
          <i class="fa fa-map"></i> فتح الخريطة
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import { BImg } from "bootstrap-vue";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
  },
  created() {
    checkPer(this, "groups", "add");
    var last_point = null,
      g = this;
    g.pasteinterval = setInterval(function () {
      navigator.clipboard.readText().then((text) => {
        if (text.includes(",") && text.includes(".") && !text.includes("/")) {
          if (text != last_point) {
            g.addPoint(text);
            last_point = text;
          }
        }
      });
    }, 500);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (r) {
        g.mylat = r.coords.latitude;
        g.mylong = r.coords.longitude;
      });
    } else {
      alert("متصفحك لا يدعم الخرائط");
    }

    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
  },
  beforeDestroy() {
    clearInterval(this.pasteinterval);
  },
  data() {
    return {
      sections: [],
      section_id: null,
      viewmap: false,
      zoom: 13,
      zoom2: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      ///////
      mylat: null,
      mylong: null,
      title: null,
      noupdatelocation: false,
      pasteinterval: null,
      points: [],
      details: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    addMarker(event) {
      this.addPoint(event.latlng.lat + "," + event.latlng.lng);
      //this.markers.push(event.latlng);
    },
    addPoint(point) {
      if (confirm("هل تريد اضافة الاحداثيات المنسوخة؟")) {
        var g = this;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "تم لصق نقطة توقف",
            icon: "CopyIcon",
            variant: "info",
          },
        });
        var audio = new Audio(
          "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
        );
        audio.play();
        g.points.push({
          lat: point.split(",")[0].trim(),
          long: point.split(",")[1].trim(),
        });
      }
    },
    deletePoint(lat) {
      var arr = [];
      this.points.forEach((element) => {
        if (element.lat != lat) {
          arr.push(element);
        }
      });
      this.points = arr;
    },
    add() {
      var g = this;
      $.post(api + "/admin/groups/add", {
        jwt: this.user.jwt,
        title: this.title,
        points: this.points,
        details: this.details,
        noupdatelocation: this.noupdatelocation,
        section_id: this.section_id,
      })
        .then(function (e) {
          e = JSON.parse(e);
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم اضافة خط السير بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.details = null;
            g.title = null;
            g.points = [];
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script><style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}
.badge {
  border-left: 2px solid #0099cc;
}
#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>